import React from 'react';// Import the Image component from next
import UserResearch from '../assets/Valor/research.png';
import UIUXDesign from '../assets/Valor/ux-design.png'; 
const SectionUx = () => {
  return (
    <div id='skills' className='w-full lg:h-screen p-8 py-8 mt-0 mb-0'>
      <div className='max-w-[1240px] mx-auto flex flex-col justify-center text-center lg:text-right'>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          Diferencias entre el Ux y el Ui
        </p>
        <h2 className='py-2'>
          Experiencia de usuario y diseños personalizados a negocios.
        </h2>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8'>
        {renderServiceCard('Investigación de Usuarios', 'Realizamos investigaciones para comprender las necesidades y comportamientos de los usuarios.', UserResearch)}
        {renderServiceCard('Diseño de Interfaz', 'Creamos interfaces atractivas y funcionales centradas en la experiencia del usuario.', UIUXDesign)}
        {/* Add more service cards as needed */}
      </div>
    </div>
  );
};

// Function to render individual service card
const renderServiceCard = (title, description, imageSrc) => (
  <div
    key={title}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className='p-8 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'
  >
    <div className='grid grid-cols-2 gap-4 justify-center items-center'>
      <div className='m-auto'>
        <img
          src={imageSrc}
          width='64px'
          height='64px'
          alt={title}
        />
      </div>
      <div className='flex flex-col items-center justify-center'>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

export default SectionUx;
