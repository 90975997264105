import React from 'react';
import a from "../assets/Seo/a.png";
import b from "../assets/Seo/b.jpg";
import c from "../assets/Seo/c.png";

function Dashboard() {
  const images = [a, b, c];
  return (
    <div style={{ textAlign: 'center' }}>
   
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            style={{ width: '30%', height: 'auto', borderRadius: '8px', margin: '0 10px' }}
          />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
