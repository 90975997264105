import React from 'react';
import Main from '../components/Main';
import Projects from '../components/Projects';
import Skills from '../components/Skills';
import "../styles/globals.css"
function Home() {
  return (
    <div>

      <Main />

      <Projects />

      <Skills />


  <div style={{
      fontFamily: 'Arial, sans-serif',
      border: '1px solid #ccc',
      borderRadius: '8px',
      padding: '16px',
      maxWidth: '800px',
      margin: 'auto',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '42px',
    }}>
      <p style={{
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '12px',
      }}>Suscríbete a nuestro plan mensual que incluye los siguientes servicios:</p>
      <ul>
        <li style={{
          listStyleType: 'none',
          marginBottom: '8px',
        }}>SEO (Optimización para Motores de Búsqueda)</li>
        <li style={{
          listStyleType: 'none',
          marginBottom: '8px',
        }}>UI/UX (Diseño de Interfaz de Usuario/Experiencia de Usuario)</li>
        <li style={{
          listStyleType: 'none',
          marginBottom: '8px',
        }}>Desarrollo Web</li>
        <li style={{
          listStyleType: 'none',
          marginBottom: '8px',
        }}>Asesoría en Tecnología Digital</li>
        <li style={{
          listStyleType: 'none',
          marginBottom: '8px',
        }}>Plan de Marketing Personalizado</li>
      </ul>
    </div>




    </div>
  );
}

export default Home;


