
import React from 'react';
import cryptoImg from '../assets/projects/crypto.jpg';


const Lookexclusivo = () => {
  return (
    <div className='w-full'>
      <div className='w-screen h-[50vh] relative'>
        <div className='absolute top-0 left-0 w-full h-[50vh] bg-black/70 z-10' />
        <img
          className='absolute z-1'
          layout='fill'
          objectFit='cover'
          src={cryptoImg}
          alt='/'
        />
        <div className='absolute top-[70%] max-w-[1240px] w-full left-[50%] right-[50%] translate-x-[-50%] translate-y-[-50%] text-white z-10 p-2'>
          <h2 className='py-2'>Gustautos</h2>
          <h3>Next JS / Tailwind / PostgreSQL</h3>
        </div>
      </div>

      <div className='max-w-[1240px] mx-auto p-2 grid md:grid-cols-5 gap-8 py-8'>
        <div className='col-span-4'>
          <p>Sitio Web</p>
          <h2>Guesitas.com</h2>


        <p>
LookExclusivo es mucho más que una tienda de moda. Es el destino perfecto para aquellos
con un estilo único y una pasión por la originalidad. Nuestra colección de busos para hombre
y mujer está diseñada pensando en hackers y personas que aprecian el buen gusto.
    Cada prenda que ofrecemos en LookExclusivo es una declaración de estilo audaz y vanguardista.
    Aquí, no solo encontrarás busos, sino una expresión auténtica de la individualidad.
    Nuestra tienda se enorgullece de proporcionar opciones que no solo son elegantes,
    sino que también reflejan personalidades valientes. Desde diseños ingeniosos hasta
    detalles únicos, cada buso en LookExclusivo cuenta una historia propia. Nos esforzamos
por brindar una experiencia de compra excepcional a nuestros clientes. Navega por nuestra
colección en línea y descubre una selección curada de busos de alta calidad que capturan
la esencia de la modernidad y la sofisticación. Ya sea que estés buscando resaltar tu pasión
    por la tecnología o simplemente quieras destacar con estilo, LookExclusivo tiene lo que necesitas.
        Te invitamos a explorar nuestra tienda en línea y encontrar el buso perfecto que se adapte
a tu personalidad y gustos únicos. En LookExclusivo, cada prenda es una oportunidad para
        expresarte y mostrar al mundo tu estilo individual y audaz.
</p>


          <a
            href='https://github.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4 mr-8'>Code</button>
          </a>
          <a
            href='https:gustautos.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4'>Demo</button>
          </a>

        </div>
        <div className='col-span-4 md:col-span-1 shadow-xl shadow-gray-400 rounded-xl py-4'>
          <div className='p-2'>
            <p className='text-center font-bold pb-2'>tecnologías</p>
            <div className='grid grid-cols-3 md:grid-cols-1 '>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> React
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Javascript
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Django
              </p>
            </div>
          </div>
        </div>
        <a href='/'>
          <p className='underline cursor-pointer'>Volver</p>
        </a>
      </div>
    </div>
  );
};

export default Lookexclusivo;
