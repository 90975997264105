import React from 'react';
import extintores from '../assets/projects/extintoresjm.jpg';
import './Extintoresjm.css';  // Importa el archivo CSS

const Extintoresjm = () => {
  return (
    <div className="extintores-container">
      <div className="extintores-header">
        <div className="extintores-overlay" />
        <img
          className="extintores-background-image"
          src={extintores}
          alt="Extintores JM"
        />
        <div className="extintores-header-content">
          <h2>extintoresjm</h2>
          <h3>React JS / CSS / Django</h3>
        </div>
      </div>
      <div className="extintores-content">
        <div className="extintores-main-content">
          <p>Web de extintores</p>
          <h2>Extintoresmj.com.co</h2>
          <p>
            ExtintoresJM es mucho más que un negocio de venta de extintores en el Valle de Aburrá. 
            Nuestra plataforma en línea te ofrece una solución confiable y conveniente para proteger tu seguridad. 
            Explora nuestro catálogo de extintores diseñados para garantizar la tranquilidad en cualquier entorno. 
            En ExtintoresJM, hemos construido un sitio web utilizando tecnologías como React JS y Firebase para demostrar nuestra 
            experiencia en brindar soluciones avanzadas. Nuestra aplicación permite a los usuarios crear cuentas seguras utilizando 
            su dirección de correo electrónico, brindándoles la capacidad de acceder y administrar sus extintores de manera eficiente. 
            Además, implementamos enrutamiento dinámico a través del paquete React Router DOM para una navegación fluida. 
            ExtintoresJM también se integra con la API Coin Gecko para garantizar un control de calidad constante. 
            En ExtintoresJM, estamos comprometidos a ofrecer más que productos; ofrecemos tranquilidad y seguridad a través de 
            una plataforma moderna y eficiente que pone el control en manos de nuestros clientes.
          </p>
          <div className="extintores-buttons">
            <a
              href="https://github.com"
              target="_blank"
              rel="noreferrer"
            >
              <button className="extintores-button">Code</button>
            </a>
            <a
              href="https://extintoresjm.com.co"
              target="_blank"
              rel="noreferrer"
            >
              <button className="extintores-button">Demo</button>
            </a>
          </div>
        </div>

        <div className="extintores-sidebar">
          <div className="extintores-technologies">
            <p className="extintores-title">Tecnologías</p>
            <div className="extintores-tech-list">
              <p>React</p>
              <p>CSS</p>
              <p>MySQL</p>
            </div>
          </div>
        </div>
        <a href="/#projects">
          <p className="extintores-back-link">Volver</p>
        </a>
      </div>
    </div>
  );
};

export default Extintoresjm;
