import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Importar componentes de la aplicación
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton';
// Importar páginas de la aplicaciónn
import Home from './pages/Home';
import About from './pages/About';
import Amky from './pages/Amky';
import Backend from './pages/Backend';
import Contact from './pages/Contact';
import Crypto from './pages/Crypto';
import ExtintoresJM from './pages/Extintoresjm';
import FrontendPage from './pages/FrontendPage';
import Guesitas from './pages/Guesitas';
import Gustautos from './pages/Gustautos';
import LookExclusivo from './pages/Lookexclusivo';
import Mobil from './pages/Mobil';
import Property from './pages/Property';
import RestauranteAsia from './pages/RestauranteAsia';
import S from './pages/Seo';
import TianCheng from './pages/TianCheng';
import Twitch from './pages/Twitch';
import U from './pages/UxUiPage';
import NotFound from './pages/NotFound';
const App = () => {
  return (
    <Router>
    {/* Componentes principales */}
    <Navbar />
    <Routes>
      {/* Definición de rutas */}
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Amky" element={<Amky />} />
      <Route path="/Backend" element={<Backend />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Crypto" element={<Crypto />} />
      <Route path="/Extintoresjm" element={<ExtintoresJM />} />
      <Route path="/FrontendPage" element={<FrontendPage />} />
      <Route path="/Guesitas" element={<Guesitas />} />
      <Route path="/Gustautos" element={<Gustautos />} />
      <Route path="/Lookexclusivo" element={<LookExclusivo />} />
      <Route path="/Mobil" element={<Mobil />} />
      <Route path="/Property" element={<Property />} />
      <Route path="/RestauranteAsia" element={<RestauranteAsia />} />
      <Route path="/Seo" element={<S />} />
      <Route path="/TianCheng" element={<TianCheng />} />
      <Route path="/Twitch" element={<Twitch />} />
      <Route path="/UxUiPage" element={<U />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    {/* Componente del botón flotante de WhatsApp */}
    <FloatingWhatsAppButton />
    {/* Componente de pie de página */}
    <Footer />
  </Router>
  );
};
export default App;
