import React from 'react';

const SeoItems = () => {
  return (
    <div>
      <h2 className='text-3xl font-bold text-[#5651e5]'>
        Impulsa Tu Éxito en Línea con Amky Digital: Servicios de SEO
      </h2>
      <p className='text-lg mt-4'>
        En Amky Digital, estamos dedicados a maximizar tu presencia en línea y aumentar tu visibilidad en los motores de búsqueda. Nuestros servicios de SEO (Search Engine Optimization) están diseñados para garantizar que tu sitio web se encuentre en las primeras posiciones de los resultados de búsqueda, lo que significa más tráfico, más clientes potenciales y un mayor éxito en línea.
      </p>
      <p className='text-lg mt-4'>
        Nuestro equipo de expertos en SEO se especializa en diversas áreas clave para ofrecerte resultados sobresalientes:
      </p>
      <ul className="text-left list-disc pl-6 text-lg mt-4">
        <li><strong>SEO Técnico:</strong> Optimizamos la estructura de tu sitio web, la velocidad de carga y otros aspectos técnicos para garantizar que esté preparado para los motores de búsqueda.</li>
        <li><strong>Optimización de Contenido:</strong> Creamos y optimizamos contenido de alta calidad con palabras clave relevantes para atraer a tu audiencia objetivo y aumentar tu clasificación en los resultados de búsqueda.</li>
        <li><strong>Análisis de Competidores:</strong> Realizamos un análisis exhaustivo de tus competidores en línea para identificar oportunidades y desarrollar estrategias efectivas.</li>
        <li><strong>SEO Local:</strong> Si tienes un negocio local, mejoramos tu visibilidad en búsquedas locales, lo que significa más tráfico local y clientes.</li>
      </ul>
      <p className='text-lg mt-4'>
        En Amky Digital, comprendemos que cada proyecto es único, por lo que adaptamos nuestras estrategias de SEO para satisfacer tus necesidades específicas. Ya sea que estés buscando aumentar tus ventas, generar leads de alta calidad o mejorar la conciencia de tu marca, estamos aquí para ayudarte a alcanzar tus objetivos de marketing en línea. ¡Confía en nosotros para impulsar tu éxito en línea con servicios de SEO de primera clase!
      </p>
    </div>
  );
};

export default SeoItems;
