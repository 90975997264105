import React from 'react';
import propertyImg from '../assets/projects/property.jpg';
import cryptoImg from '../assets/projects/crypto.jpg';
import amky from '../assets/projects/Amkydigital.png';
import extintores from '../assets/projects/extintoresjm.jpg';
import twitchImg from '../assets/projects/twitch.jpg';
import Asia from '../assets/projects/Asia.jpg';
import Tiang from '../assets/projects/Tiang.jpg';
import Gustautos from '../assets/projects/Gustautos.png';
import ProjectItem from './ProjectItem';

const Projects = () => {
  return (
    <div id='projects' className='w-full'>
      <div className='max-w-[1240px] mx-auto px-2 py-8'>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          Trabajos
        </p>
        <h2 className='py-4'>Que hacemos</h2>
        <div className='grid md:grid-cols-2 gap-8'>
          <ProjectItem
            title='Gustautos'
            backgroundImg={Gustautos}
            projectUrl='/gustautos'
            tech='React JS'
          />

          <ProjectItem
            title='EL gran tian cheng'
            backgroundImg={Tiang}
            projectUrl='/tianCheng'
            tech='React JS'
          />

          <ProjectItem
            title='Restaurante Asia'
            backgroundImg={Asia}
            projectUrl='/restauranteAsia'
            tech='React JS'
          />

          <ProjectItem
            title='Twitch UI'
            backgroundImg={twitchImg}
            projectUrl='/twitch'
            tech='Next JS'
          />

                <ProjectItem
            title='Property Finder'
            backgroundImg={propertyImg}
            projectUrl='/property'
            tech='React JS'
          />

          <ProjectItem
            title='Crypto App'
            backgroundImg={cryptoImg}
            projectUrl='/crypto'
            tech='React JS'
          />
          

          <ProjectItem
            title='Extintores Jm'
            backgroundImg={extintores}
            projectUrl='/extintoresjm'
            tech='React JS'
          />


          <ProjectItem
            title='Amky'
            backgroundImg={amky}
            projectUrl='/Amky'
            tech='Next JS'
          />

   
   


        </div>
      </div>
    </div>
  );
};

export default Projects;
