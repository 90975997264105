import React from 'react';
import AboutImg from '../assets/about.png';

const About = () => {
  return (
    <div id='about' className='w-full md:h-screen p-2 flex items-center py-16'>
      <div className='max-w-[1240px] m-auto md:grid grid-cols-3 gap-8'>
        <div className='col-span-2'>
          <p className='uppercase text-xl tracking-widest text-[#5651e5]'>
            Acerca de
          </p>
          <h2 className='py-4'>Amkydigital</h2>
          <p className='py-2 text-gray-600'>
            En AmkyDigital, nos especimos en construir aplicaciones de
            interfaz de usuario (UI) responsivas para dispositivos móviles que se
            conectan con API y otras tecnologías backend. 
          </p>

 <p className='py-2 text-gray-600'>
        Estamos apasionados por
            aprender nuevas tecnologías y comprendemos que hay más de una manera
            de lograr una tarea. 
          </p>


          <p className='py-2 text-gray-600'>
        Aunque somos más competentes en la creación de
            aplicaciones de frontend utilizando HTML, CSS, Javascript y React,
            aprendemos rápidamente y podemos adoptar nuevas pilas tecnológicas
            según sea necesario.
          </p>


    <p className='py-2 text-gray-600'>
       Creemos que ser un gran desarrollo no implica
            usar un lenguaje específico, sino elegir la mejor herramienta para el
            trabajo.
          </p>

          <a href='/#projects'>
            <p className='py-2 text-gray-600 underline cursor-pointer'>
              Echa un vistazo a algunos de mis proyectos más recientes.
            </p>
          </a>
        </div>
        <div className='w-full h-auto m-auto shadow-xl shadow-gray-400 rounded-xl flex items-center justify-center p-4 hover:scale-105 ease-in duration-300'>
          <img src={AboutImg} className='rounded-xl' alt='/' />
        </div>
      </div>
    </div>
  );
};

export default About;
