import React from 'react';
import amkyImg from '../assets/projects/Amkydigital.png';
import './Amky.css'; // Importa el archivo CSS

const Amky = () => {
  return (
    <div className="amky-container">
      <div className="amky-header">
        <div className="amky-overlay" />
        <img
          className="amky-image"
          src={amkyImg}
          alt="Amkydigital.com"
        />
        <div className="amky-text">
          <h2>Amkydigital.com</h2>
          <h3>SEO Técnico Avanzado / Estrategias SEO Ux Ui / Análisis de Datos</h3>
        </div>
      </div>

      <div className="amky-content">
        <div className="amky-info">
          <p>Proyecto</p>
          <h2>amkydigital.com</h2>
          <p>
            Desarrollé este proyecto con un enfoque en SEO técnico avanzado y estrategias SEO Ux Ui.
            El sitio utiliza análisis de datos para mejorar la experiencia del usuario y la visibilidad en los motores de búsqueda.
          </p>
          <a href="https://www.amkydigital.com" target="_blank" rel="noreferrer">
            <button className="amky-button">Demo</button>
          </a>
        </div>

        <div className="amky-tech">
          <div className="tech-header">Tecnologías</div>
          <div className="tech-list">
            <p className="tech-item">React.js</p>
            <p className="tech-item">Node.js</p>
          </div>
        </div>
        <a href="/#projects" className="amky-back">
          <p className="amky-back-text">Volver</p>
        </a>
      </div>
    </div>
  );
};

export default Amky;







