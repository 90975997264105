import React, { useState } from 'react';

const ContactForm = () => {
const [formData, setFormData] = useState({
   email: '',
});

const handleChange = (e) => {
   setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
   e.preventDefault();

   try {
     const response = await fetch('https://getform.io/f/9f8131e0-e281-4aa0-ba8a-437f2f797207', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(formData),
     });

     if (response.ok) {
       alert('Mensaje enviado con éxito');
     } else {
       alert('Error al enviar el mensaje');
     }
   } catch (error) {
     console.error('Error:', error);
   }
};

return (

<div className="pb-4">

<h2 className="text-xl font-bold mb-4 text-white">Newsletter</h2>
<form onSubmit={handleSubmit}>
     <label htmlFor="email">Correo electrónico:</label>
     <div className="flex flex-col md:flex-row md:space-x-4 md:items-center">
     <input type="email" id="email" name="email" placeholder="Enter your email" className="px-4 py-2 w-full md:w-3/4 rounded-lg bg-gray-100" value={formData.email} onChange={handleChange} required />
     </div>

  <button className="bg-blue-500 hover:bg-blue-600 text-lg px-10 py-1 rounded-lg mt-1 md:mt-2.4 md:ml-3" type='submit'>
    <div className="flex items-center">
      Subcribete
    </div>
  </button>
   </form>
</div>

   
);
};

export default ContactForm;
