import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import videoSource from '../assets/video/color.mp4';
const HeroUx = () => {
  const titleVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const subTitleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='relative py-8 mx-auto max-w-screen-xl h-screen'
    >
      <video autoPlay muted loop className='absolute inset-0 object-cover w-full h-full'>
        <source src={videoSource} type='video/mp4' />
        Tu navegador no soporta el tag de video.
      </video>
   
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className='py-44 text-center relative z-10 bg-transparent'
      >
  <motion.h1
          variants={titleVariants}
          initial="hidden"
          animate="visible"
          className='text-5xl font-bold mb-5'
        >
          <span style={{ color: '#4285F4', background: ' white'  }}>Bienvenido </span>
          <span style={{ color: '#0F9D58', background: ' white' }}>a la </span>
          <span style={{ color: '#F4B400', background: ' white' }}>revolución </span>
          <span style={{ color: '#DB4437', background: ' white'}}>de diseño web </span>
          <span style={{ color: '#4285F4', background: ' white' }}>y UX/UI</span>
        </motion.h1>
        <motion.p
          variants={subTitleVariants}
          initial="hidden"
          animate="visible"
          className='text-2xl mb-10'
        >
          <span style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'rgba(250, 250, 250, 0.9)', borderRadius: "6px", padding: "10px" }}>
            <strong>En Valor,</strong> te acompañamos en cada etapa del proceso creativo.
          </span>
        </motion.p>
        <Link to='/contact'>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className='inline-block px-8 py-4 text-xl font-bold text-white bg-blue-700 rounded-lg shadow-lg hover:bg-blue-200 hover:text-black'
          >
            Contáctanos
          </motion.div>
        </Link>
      </motion.div>
    </motion.div>
  );
}
export default HeroUx;
