import React from 'react';
import { Link } from 'react-router-dom';
import twitchImg from '../assets/projects/twitch.jpg';

const Twitch = () => {
  return (
    <div className='relative w-full'>
      <div className='absolute top-0 left-0 w-full h-[50vh] bg-black/70 z-10' />
      <img
        className='absolute z-1 w-full h-[50vh] object-cover'
        src={twitchImg}
        alt='Twitch Project'
      />
      <div className='absolute top-[70%] max-w-[1240px] w-full left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white z-10 p-2'>
        <h2 className='py-2'>Twitch UI</h2>
        <h3>Next JS / Tailwind / Next Auth</h3>
      </div>
      <div className='max-w-[1240px] mx-auto p-2 grid md:grid-cols-5 gap-8 py-8'>
        <div className='col-span-4'>
          <p>Proyecto</p>
          <h2>medecode.net</h2>
          <p>
            Esta aplicación fue construida utilizando NEXT.JS y tiene estilos con Tailwind CSS.
            La aplicación está alojada estáticamente en Vercel. Se trata de una recreación
            con capacidad de respuesta para dispositivos móviles de Twitch.tv y cuenta con Next/Auth.js
            para la autenticación. Los usuarios pueden elegir autenticarse con
            una cuenta de Github o una cuenta de Google. Algunas características destacadas de este
            proyecto son la carga diferida de imágenes utilizando el componente Image, enrutamiento
            integrado y el contexto de Next/Auth.
          </p>

          <a
            href='https://twitch'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4 mr-8'>Demo</button>
          </a>
          <a
            href='https://github.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4'>Código Fuente</button>
          </a>
        </div>
        <div className='col-span-4 md:col-span-1 shadow-xl shadow-gray-400 rounded-xl py-4'>
          <div className='p-2'>
            <p className='text-center font-bold pb-2'>Tecnologías</p>
            <div className='grid grid-cols-3 md:grid-cols-1'>
              <div label='Next.JS' />
              <div label='Tailwind' />
              <div label='Javascript' />
              <div label='Next Auth' />
              <div label='Github Auth' />
              <div label='Google Auth' />
            </div>
          </div>
        </div>
        <Link to='/#projects'>
          <p className='underline cursor-pointer'>Volver</p>
        </Link>
      </div>
    </div>
  );
};

export default Twitch;
