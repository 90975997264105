import React from 'react';
import ImageBackground from '../assets/5301586.webp';
import SEO from '../components/SEO';
import SeoItems from '../components/SeoItems';
import SEOPage from '../components/SEOPage';
import GoogleSeo from '../assets/googleSeo.jpg';
import './HeroSection.css';
import './Seo.css';

const Seo = () => {
  return (
    <div className='w-full'>
      <div className="hero-section relative" style={{ 
        backgroundImage: `url(${ImageBackground})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center' 
      }}>
        <div className="content-container flex justify-center items-center h-auto md:h-screen">
          <div className="text-container text-center max-w-screen-lg">
            <h2 className="py-6 sm:py-12 text-3xl sm:text-5xl font-bold text-[#121212]">
              Impulsa Tu Éxito en Línea con <span className="text-[#52c8ff]">Amky Digital</span>: Servicios de{' '}
              <span className="text-[#52c8ff]">SEO</span>.
            </h2>
            <a
              href='https://wa.me/573053550028?text=Hola%20Amky,%20quiero%20más%20información'
              target='_blank'
              className="bg-[#33B0E5] text-[#121212] px-6 py-1 rounded-md mt-4 sm:mt-8 inline-block transition duration-300 hover:bg-[#4743e0]">
              ¡Potencia tu sitio con SEO Técnico Profesional!
            </a>
          </div>
        </div>
      </div>
      <div className="israel flex justify-center items-center">
        <div className="max-w-screen-lg w-full p-4">
          <img src={GoogleSeo} alt="Google SEO" className="w-full" />
        </div>
      </div>
      <div className="israel flex flex-col justify-center items-center">
        <div className="max-w-screen-lg w-full p-4">
          <div className="flex flex-col md:flex-row">
            <div className='w-full md:text-left'>
              <div className='mt-4 md:mt-8 mx-auto'>
                <div className='aspect-ratio-16/9'>
                  <iframe
                    width='560'
                    height='315'
                    src='https://www.youtube.com/embed/_hNCh4oF4IE?si=jnYLqNy-Y3SzYE3z'
                    title='Video de SEO'
                    frameBorder='0'b
                    allowFullScreen
                    className='w-full' 
                    style={{ maxWidth: '100%', height: '80vh' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="israel flex justify-center items-center">
        <div className="max-w-screen-lg w-full p-4">
          <SeoItems />
        </div>
      </div>

      <div className="israel flex justify-center items-center">
        <div className="max-w-screen-lg w-full p-4">
          <SEO />
        </div>
      </div>
    </div>
  );
};

export default Seo;
