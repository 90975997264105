import React from 'react';
import Img from '../assets/projects/Gustautos.png';
import './Gustautos.css'; // Importa el archivo CSS

const Gustautos = () => {
  return (
    <div className="gustautos-container">
      <div className="gustautos-header">
        <div className="gustautos-overlay" />
        <img className="gustautos-image" src={Img} alt="Gustautos" />
        <div className="gustautos-text">
          <h2>Gustautos</h2>
          <h3>React JS / Tailwind</h3>
        </div>
      </div>

      <div className="gustautos-content">
        <div className="gustautos-info">
          <p>Sitio Web</p>
          <h2>Gustautos.com</h2>
          <p>Gustautos es una página de venta de carros nuevos y usados.  Recientemente adquirieron un sitio web informativo con enlaces a redes sociales para proporcionar a los visitantes información detallada sobre sus productos y servicios. En Gustautos, estamos comprometidos en brindar opciones de compra confiables y variadas a nuestros clientes. Nuestro sitio web no solo presenta una amplia gama de carros disponibles, sino que también ofrece detalles sobre las especificaciones, el historial de mantenimiento y las opciones de financiamiento. ¡Explora nuestra plataforma en línea y encuentra el carro perfecto para ti en Gustautos!</p>
          <a href="https://github.com" target="_blank" rel="noreferrer">
            <button className="gustautos-button">Code</button>
          </a>
          <a href="https://gustautos.com" target="_blank" rel="noreferrer">
            <button className="gustautos-button">Demo</button>
          </a>
        </div>

        <div className="gustautos-tech">
          <div className="tech-header">Tecnologías</div>
          <div className="tech-list">
            <div className="tech-item">React</div>
            <div className="tech-item">JavaScript</div>
            <div className="tech-item">Django</div>
          </div>
        </div>

        <a href="/" className="gustautos-back">
          <button className="gustautos-back-button">Volver</button>
        </a>
      </div>
    </div>
  );
};

export default Gustautos;



