import React from 'react';
import "./VideoUx.css";


export default function VideoUx() {
  return (
    <div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className=' max-w-[1240px]  mx-auto flex flex-wrap items-center justify-center mb-8' 
    >
      <div className='w-full md:w-1/2 md:order-2 p-4'>
        <h2 className='text-3xl font-bold text-[#5651e5]'>Diseño UX y UI</h2>
        <p className='text-lg mt-4'>
          Nuestro enfoque en el Diseño de Experiencia de Usuario (UX) y el
          Diseño de Interfaz de Usuario (UI) se basa en la creación de
          experiencias digitales excepcionales y atractivas para tus usuarios.
        </p>
        <p className='text-lg mt-4'>
          Utilizamos Figma para diseñar y colaborar en interfaces de usuario de
          alta calidad, y CSS para estilizar y dar vida a nuestras creaciones.
          Además, nuestras habilidades en herramientas como Illustrator,
          Photoshop, JavaScript, Tailwind CSS y Sass nos permiten crear diseños
          atractivos y funcionales.
        </p>
      </div>
      <div className='w-full md:w-1/2 md:order-1'>


      <div className='mt-8 mx-auto max-w-full'>
  <div className='aspect-w-16 aspect-h-9'>
    <iframe
      width='560'
      height='415'
      src='https://www.youtube.com/embed/JDiihtb8I9c?autoplay=1&loop=1'
      title='Video de UX/UI'
      frameBorder='0' 
      allowFullScreen
      className='w-full' 
      style={{ maxWidth: '100%', height: '66vh' }}
    ></iframe>
  </div>
</div>





      </div>
    </div>
  );
}
