import React from 'react';
import Ends from '../components/Ends';
import ImageBackground from '../assets/5575128.webp'; 
import Dashboard from '../components/Dashboard';

const Backend = () => {
  const ctaUrl = '/contact';
  return (
    <div className='w-full'>
     <div className="hero-section" style={{ backgroundImage: `url(${ImageBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="content-container flex justify-center items-center h-screen relative z-10">
        <div className="text-container text-center max-w-screen-lg">
          <h3 className="py-12 text-5xl font-bold text-gray">Eleva Tu Éxito con Amky Digital</h3>
          <p className="text-xl mt-4 text-blue-900 font-semibold">
            Impulsa la gestión de tu sitio con nuestro potente panel de administración.
          </p>
          <p className="text-xl mt-4 text-blue-900">
            Desarrollado con tecnologías de vanguardia, como Django para el backend y Node.js para el frontend, nuestro panel te brinda un control total sobre tu contenido y estadísticas.
          </p>
          <a
            href={ctaUrl}
            className="bg-[#33B0E5] text-white px-8 py-4 rounded-md mt-8 inline-block transition duration-300 hover:bg-[#4743e0]"
          >
            ¡Haz que tu visión digital cobre vida con Amky!
          </a>
        </div>
      </div>
    </div>
    <Dashboard/>
    <Ends />
  </div>
);
};
export default Backend;

