import React from 'react';
import UxServices from '../components/Ux_Services';
import HeroUxui from '../components/HeroUX';
import ContacButton from '../components/ContacButton';
import SectionUx from '../components/SectionUx';
import VideoUx from '../components/VideoUx';
import UX_UI from "../components/UX_UI";
import "../styles/globals.css"
const UxUiPage = () => {
  return (
    <div className='w-full'>
      <HeroUxui />
      <div className='py-28 max-w-[1240px] mx-auto text-center mb-2'>
        <h2 className='text-3xl font-bold text-[#5651e5]'>
          Eleva Tu Éxito con Amky digital
        </h2>
        <p className='text-lg mt-2'>
          Diseñamos experiencias digitales excepcionales. Nuestro equipo élite
          potenciará tu presencia en línea con un diseño UX/UI de clase mundial.
        </p>
        <p className='text-lg mt-2'>
          Cada detalle importa. Entregamos todo en PDF: logotipos, colores,
          mapas, ¡todo! En AMKY Digital, no solo creamos sitios web, creamos
          memorables experiencias.
        </p>
      </div>
      <ContacButton />
      <UxServices />
      <SectionUx />
      <VideoUx />
      <UX_UI/>
    </div>
  );
};
export default UxUiPage;
