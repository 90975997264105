import React from 'react';
import Prototyping from '../assets/Valor/prototype.png';
import VisualDesign from '../assets/Valor/web-design.png';
import UsabilityTesting from '../assets/Valor/usability.png';
import Accessibility from '../assets/Valor/easy-access.png';
import UserResearch from '../assets/Valor/research.png';
import UIUXDesign from '../assets/Valor/ux-design.png';

const Ux_Services = () => {
  return (
    <div className='py-16 max-w-[1240px] mx-auto flex flex-col justify-center h-full'>
      <div className='mt-30 mb-40'>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          UX y UI
        </p>
        <h2 className='py-4'>Diseño Profesional</h2>
        <div className='grid grid-cols-2 lg:grid-cols-4 gap-8'>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={UserResearch} width='64px' height='64px' alt='Investigación de Usuarios' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Investigación de Usuarios</h3>
                <p>Realizamos investigaciones para comprender las necesidades y comportamientos de los usuarios.</p>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={UIUXDesign} width='64px' height='64px' alt='Diseño de Interfaz' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Diseño de Interfaz</h3>
                <p>Creamos interfaces atractivas y funcionales centradas en la experiencia del usuario.</p>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Prototyping} width='64px' height='64px' alt='Prototipado' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Prototipado</h3>
                <p>Iteramos y validamos ideas a través de prototipos para mejorar la usabilidad.</p>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={VisualDesign} width='64px' height='64px' alt='Diseño Visual' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Diseño Visual</h3>
                <p>Aplicamos principios de diseño para crear una estética atractiva y coherente.</p>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={UsabilityTesting} width='64px' height='64px' alt='Pruebas de Usabilidad' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Pruebas de Usabilidad</h3>
                <p>Evaluamos interfaces con usuarios reales para identificar áreas de mejora.</p>
              </div>
            </div>
          </div>
          <div className='p-6 shadow-xl rounded-xl hover:scale-105 ease-in duration-300'>
            <div className='grid grid-cols-2 gap-4 justify-center items-center'>
              <div className='m-auto'>
                <img src={Accessibility} width='64px' height='64px' alt='Accesibilidad' />
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3>Accesibilidad</h3>
                <p>Diseñamos interfaces que sean inclusivas y accesibles para todos los usuarios.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ux_Services;
