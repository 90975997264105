
import React from 'react';
import tian from '../assets/projects/Tiang.jpg';
import './TianCheng.css';

const TianCheng = () => {
  return (
    <div className="tiancheng-container">
      <div className="tiancheng-banner">
        <div className="tiancheng-overlay" />
        <img
          className="tiancheng-img"
          src={tian}
          alt="El Gran Tian Cheng"
        />
        <div className="tiancheng-title-container">
          <h2>El Gran Tiang Cheng</h2>
          <h3>React JS / CSS</h3>
        </div>
      </div>

      <div className="tiancheng-content">
        <div className="tiancheng-description">
          <p>Restaurante Website</p>
          <h2>elgrantiancheng.com</h2>
          <p>
            El Gran Tian Cheng te invita a sumergirte en una experiencia culinaria excepcional.
a través de su sitio web. Con la sofisticación de su diseño y la tecnología de React,
nuestro sitio ofrece una ventana virtual a la auténtica cocina china que ofrecemos.
 Descubre la fusión perfecta de tradición y modernidad en cada página.
  Desde el momento en que ingreses al sitio, te recibirá un diseño elegante que refleja
   la esencia de la cultura y la gastronomía china. El uso de React permite una
    navegación fluida y una interacción intuitiva, mientras que los estilos de
    CSS aportan una estética visualmente atractiva y envolvente. Explora nuestro
    variado menú de platos, cada uno cuidadosamente presentado con su descripción
    y precios. Además, el sitio te brinda la comodidad de realizar reservas en línea
     para asegurar tu lugar en una experiencia gastronómica memorable. Ya sea que estés
      buscando sabores auténticos o simplemente quieras explorar la riqueza de la cocina
       china, el sitio web de El Gran Tian Cheng es tu puerta de entrada a un viaje culinario inigualable.
          </p>
          <a
            href="https://github.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="tiancheng-button">Code</button>
          </a>
          <a
            href="https://elgrantiancheng.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="tiancheng-button">Demo</button>
          </a>
        </div>
        <div className="tiancheng-tech-card">
          <div className="tiancheng-tech-title">Tecnologías</div>
          <div className="tiancheng-tech-grid">
            <div className="tiancheng-tech-item">React</div>
            <div className="tiancheng-tech-item">JavaScript</div>
            <div className="tiancheng-tech-item">CSS</div>
          </div>
        </div>
        <a href="/#projects" className="tiancheng-back-link">
          Volver
        </a>
      </div>
    </div>
  );
};

export default TianCheng;


