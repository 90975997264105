import React from 'react';
import Frontend from '../components/Frontend';

const FrontendPage = () => {
  return (
    <div className='w-full'>
      <div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className='py-4 max-w-[1240px] mx-auto text-center mb-40'
      >
        <h2 className='text-3xl font-bold text-[#5651e5]'>
          Eleva Tu Éxito con Amky digital
        </h2>
        <p className='text-lg mt-4 text-gray-800'>
          Diseñamos experiencias digitales excepcionales. Nuestro equipo élite potenciará tu presencia en línea con un diseño UX/UI de clase mundial.
        </p>
        <p className='text-lg mt-4 text-gray-800'>
          Cada detalle importa. Entregamos todo en PDF: logotipos, colores, mapas, ¡todo! En AMKY Digital, no solo creamos sitios web, creamos memorables experiencias.
        </p>
        {/* Llamado a la Acción (CTA) */}
        <a
          href='/contacto' // Reemplaza con la URL de tu página de contacto
          className='bg-[#5651e5] text-white px-6 py-3 rounded-md mt-8 inline-block transition duration-300 hover:bg-[#4743e0]'
        >
          ¡Haz que tu visión digital cobre vida con Amky!
        </a>
      </div>
      <Frontend />
    </div>
  );
};

export default FrontendPage;
