
import React from 'react';
import asia from '../assets/projects/Asia.jpg';
import './RestauranteAsia.css'; // Importa el archivo CSS

const RestauranteAsia = () => {
  return (
    <div className="restauranteasia-container">
      <div className="restauranteasia-header">
        <div className="restauranteasia-overlay" />
        <img
          className="restauranteasia-image"
          src={asia}
          alt="Restaurante Asia"
        />
        <div className="restauranteasia-text">
          <h2>Restaurante Asia</h2>
          <h3>React JS / CSS</h3>
        </div>
      </div>

      <div className="restauranteasia-content">
        <div className="restauranteasia-info">
          <p>Restaurante Chino</p>
          <h2>Restauranteasia.com.co</h2>
          <p>
            Restaurante Asia te da la bienvenida a su sitio web moderno y
            elegante, creado con la potencia de React JS y CSS.React y la belleza de los estilos de CSS. Explora una experiencia culinaria excepcional 
desde la comodidad de tu pantalla. Nuestro sitio web no solo te brinda una vista detallada
 de nuestro variado menú de platos asiáticos, sino que también te sumerge en un ambiente
  virtual que refleja la esencia de nuestros sabores. Cada página está diseñada meticulosamente,
   aprovechando la flexibilidad de React para ofrecerte una navegación fluida y una interacción intuitiva.
    Nuestro equipo de diseñadores ha empleado técnicas de CSS para crear
     una apariencia única que refleja la estética del Lejano Oriente. 
     Los colores, las tipografías y los efectos visuales se combinan 
     en armonía para proporcionarte una experiencia visualmente atractiva y agradable.
      Desde la página de inicio hasta el menú y la reserva en línea, 
      cada detalle ha sido cuidadosamente considerado para brindarte 
      un viaje gastronómico digital sin igual. Descubre una fusión perfecta de tecnología 
      y estilo en el sitio web del Restaurante Asia.
          </p>
          <a href="https://github.com" target="_blank" rel="noreferrer">
            <button className="restauranteasia-button">Code</button>
          </a>
          <a href="https://restauranteasia.com.co" target="_blank" rel="noreferrer">
            <button className="restauranteasia-button">Demo</button>
          </a>
        </div>
        <div className="restauranteasia-tech">
          <div className="tech-header">Tecnologías</div>
          <div className="tech-list">
            <div className="tech-item">React</div>
            <div className="tech-item">JavaScript</div>
            <div className="tech-item">CSS</div>
          </div>
        </div>
        <a href="/#projects" className="restauranteasia-back">
          <p className="restauranteasia-back-text">Volver</p>
        </a>
      </div>
    </div>
  );
};

export default RestauranteAsia;


