import React from 'react';
import cryptoImg from '../assets/projects/crypto.jpg';


const Guesitas = () => {
  return (
    <div className='w-full'>
      <div className='w-screen h-[50vh] relative'>
        <div className='absolute top-0 left-0 w-full h-[50vh] bg-black/70 z-10' />
        <img
          className='absolute z-1'
          layout='fill'
          objectFit='cover'
          src={cryptoImg}
          alt='/'
        />
        <div className='absolute top-[70%] max-w-[1240px] w-full left-[50%] right-[50%] translate-x-[-50%] translate-y-[-50%] text-white z-10 p-2'>
          <h2 className='py-2'>Guesitas</h2>
          <h3>Next JS / Tailwind / PostgreSQL</h3>
        </div>
      </div>
      
      <div className='max-w-[1240px] mx-auto p-2 grid md:grid-cols-5 gap-8 py-8'>
        <div className='col-span-4'>
          <p>Sitio Web</p>
          <h2>Guesitas.com</h2>

<p>
  Guesitas es mucho más que un restaurante de hamburguesas en Medellín. 
  En nuestro sitio web, te ofrecemos una experiencia culinaria completa y conveniente. 
  Navega a través de nuestro agradable diseño y descubre un menú variado de hamburguesas, 
  cada una cuidadosamente elaborada para deleitar tus sentidos.
   Con nuestro carrito de compras, puedes seleccionar y ordenar tus hamburguesas
    favoritas con facilidad. Además de visualizar las opciones del menú,
     te proporcionamos descripciones detalladas para que conozcas los sabores 
     y ingredientes de cada plato. En Guesitas, creemos que la pasión por la comida va más allá,
      por lo que nuestro sitio web no solo te permite saborear, sino también personalizar
       tus hamburguesas según tus preferencias. Disfruta de la comodidad 
       de hacer tu pedido en línea y descubre una experiencia gastronómica
        que combina sabor y conveniencia en cada clic.
</p>


          <a
            href='https://github.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4 mr-8'>Code</button>
          </a>
          <a
            href='https:gustautos.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4'>Demo</button>
          </a>
        </div>
        <div className='col-span-4 md:col-span-1 shadow-xl shadow-gray-400 rounded-xl py-4'>
          <div className='p-2'>
            <p className='text-center font-bold pb-2'>tecnologías</p>
            <div className='grid grid-cols-3 md:grid-cols-1 '>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> React
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Javascript
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Django
              </p>
            </div>
          </div>
        </div>
        <a href='/'>
          <p className='underline cursor-pointer'>Volver</p>
        </a>
      </div>
    </div>
  );
};

export default Guesitas;
