import React from "react";
import News from "./News";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Sección 1: Soluciones Digitales */}
          <div>
            <h2 className="text-xl font-bold mb-4 text-white">Soluciones Digitales Integradas para Tu Negocio</h2>
            <p className="text-gray-300 mb-4">
              Nuestra pasión es impulsar tu presencia en línea. Ofrecemos estrategias diseñadas para destacar en el mercado actual.
            </p>
            <div className="flex space-x-4">
              <SocialLink href="https://www.facebook.com/profile.php?id=100071147559587&mibextid=ZbWKwL" target="_blank" icon="facebook" />
              <SocialLink href="https://twitter.com/JoseAlexisBeta1" target="_blank" icon="twitter" />
              <SocialLink href="https://co.linkedin.com/in/jose-alexis-betancur-mira-873a48219" target="_blank" icon="linkedin" />
              <SocialLink href="https://instagram.com/amkydigital?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D" target="_blank" icon="instagram" />
            </div>
          </div>

          {/* Sección 2: Contacto */}
          <div>
            <h2 className="text-xl font-bold mb-4 text-white">Contacto</h2>
            <p className="text-gray-300 mb-4">
              Email: <a href="mailto:oficina.akmdigital@gmail.com" className="text-gray-300 hover:text-gray-100 transition duration-300">oficina.akmdigital@gmail.com</a>
            </p>
            <p className="text-gray-300 mb-4">
              Teléfono: <a href="tel:+573053550028" className="text-gray-300 hover:text-gray-100 transition duration-300">3053550028</a>
            </p>
            <p className="text-gray-300 mb-4">
              Dirección: Carrera 25 1 29 El Tesoro, Medellín
            </p>
          </div>

          {/* Sección 3: Últimas Noticias */}
          <News />

          {/* Sección 4: Links */}
          <div>
            <h2 className="text-xl font-bold mb-4 text-white">Links</h2>
            <ul className="space-y-2">
              <Link to="/" text="Home" />
              <Link to="/about" text="Nosotros" />
              <Link to="/contact" text="Contacto" />
              <Link to="tel:+573053550028" text="Asesoría" />
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

// Componente para enlaces de redes sociales
const SocialLink = ({ href, target, icon }) => {
  return (
    <a href={href} target={target} rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100 transition duration-300">
      {/* Aquí puedes agregar los íconos correspondientes */}
    </a>
  );
};

// Componente para enlaces del pie de página
const FooterLink = ({ href, text }) => {
  return (
    <li>
      <a href={href} className="text-gray-300 hover:text-gray-100 transition duration-300">
        {text}
      </a>
    </li>
  );
};

export default Footer;

