// WhatsAppButton.js
import React from 'react';
import './FloatingWhatsAppButton.css';
import whatsappQ from '../assets/whatsapp.png';

const FloatingWhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Lógica para manejar el clic en el botón de WhatsApp
    window.open('https://wa.me/573053550028?text=Hola%20Amky,%20quiero%20más%20información', '_blank');
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleWhatsAppClick}>
      <img src={whatsappQ} alt="WhatsApp" />
    </div>
  );
};

export default FloatingWhatsAppButton;
