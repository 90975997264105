import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMail, AiOutlineShoppingCart, AiFillPhone, AiFillRead } from 'react-icons/ai';

const phoneNumber = '+573053550028';

const Main = () => {
  return (
    <div id='home' className='w-full h-screen text-center'>
      <div className='max-w-[1240px] w-full h-full mx-auto p-2 flex justify-center items-center'>
        <div>
          <p className='uppercase text-sm tracking-widest text-gray-600'></p>
          <h1 className='py-4 text-gray-700'>
          <span className='text-[#5651e5]'>SEO</span>, Diseño  <span className='text-[#4CAF50]'>UX </span>y <span className='text-[#FF5722]'>UI</span>,<span className='text-[#0077B5]'> Web</span>
          </h1>
          <h2 className='py-2 text-gray-700'><p className='uppercase text-sm tracking-widest text-gray-600'>Amkydigital.com</p>Se primero en google desde 800.000 cop mensuales.</h2>
          <p className='py-4 text-gray-600 sm:max-w-[70%] m-auto'>
            Construimos con amor aplicaciones Web Front-End Back-End posicionadas en buscadores.
          </p>
          <p className='py-4 text-gray-600 sm:max-w-[70%] m-auto'>Agencia de Marketing y Desarrollo Web en Medellín Colombia.</p>
          <div className='flex items-center justify-between max-w-[330px] m-auto py-4'>
            <a href={`tel:${phoneNumber}`} target='_blank' rel='noreferrer'>
              <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                <AiFillPhone />
              </div>
            </a>
            <Link href='/contact'>
              <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                <AiOutlineMail />
              </div>
            </Link>
            <Link href='#projects'>
              <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                <AiFillRead />
              </div>
            </Link>
            <Link href='/cart'>
              <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                < AiOutlineShoppingCart />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
