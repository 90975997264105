import React from 'react'

export default function ContacButton() {
  return (
    <div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className=" max-w-[1240px] mx-auto text-center"
  >
    <div className={'py[-8]'}>
      <a
        href='/contact'
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#33B0E5] text-white px-6  rounded-md mt-2 py-1 inline-block transition duration-300 hover:bg-[#4743e0]"
      >
        ¡Haz que todo cobre vida con Amky!
      </a>
    </div>
  </div>
  )
}
