
import React from 'react';
import propertyImg from '../assets/projects/property.jpg';


const Property = () => {
  return (
    <div className='w-full'>
      <div className='w-screen h-[50vh] relative'>
        <div className='absolute top-0 left-0 w-full h-[50vh] bg-black/70 z-10' />
        <img
          className='absolute z-1'
          layout='fill'
          objectFit='cover'
          src={propertyImg}
          alt='/'
        />
        <div className='absolute top-[70%] max-w-[1240px] w-full left-[50%] right-[50%] translate-x-[-50%] translate-y-[-50%] text-white z-10 p-2'>
          <h2 className='py-2'>Property Finders</h2>
          <h3>React JS / Tailwind / Firebase</h3>
        </div>
      </div>

      <div className='max-w-[1240px] mx-auto p-2 grid md:grid-cols-5 gap-8 py-8'>
        <div className='col-span-4'>
          <p>Proyecto</p>
          <h2>medecode.net</h2>
        <p>
  Esta aplicación fue construida usando React JS y está alojada en Firebase.
   Los usuarios pueden buscar propiedades según una dirección, ciudad o código postal 
   para obtener una lista de propiedades activas actualmente en venta. Podrás ver información 
   de la propiedad, así como la ubicación específica de la propiedad integrada 
   con la API de Google Maps. La autenticación de usuarios está disponible para 
   que puedas registrarte e iniciar sesión en tu cuenta con una dirección de correo
    electrónico para guardar tus propiedades favoritas. Esto es posible gracias a la API de Zillow.
</p>

          <a
            href='https://github.com'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4 mr-8'>Code</button>
          </a>
          <a
            href='https://medecode.net'
            target='_blank'
            rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4'>Demo</button>
          </a>
        </div>
        <div className='col-span-4 md:col-span-1 shadow-xl shadow-gray-400 rounded-xl py-4'>
          <div className='p-2'>
            <p className='text-center font-bold pb-2'>Tecnologías</p>
            <div className='grid grid-cols-3 md:grid-cols-1'>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> React
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Tailwind
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Javascript
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Firebase
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Google API
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <div className='pr-1' /> Zillow API
              </p>
            </div>
          </div>
        </div>
        <a href='/#projects'>
          <p className='underline cursor-pointer'>Volver</p>
        </a>
      </div>
    </div>
  );
};

export default Property;
