import React from 'react';
import '../styles/globals.css';
import ContactImg from '../assets/contact.jpg';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div id='contact' className='max-w-[1240px] mx-auto py-24'>
      <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
        Contacto
      </p>
      <h2 className='py-4 text-3xl font-bold'>Contáctanos</h2>
      <div className='grid lg:grid-cols-5 gap-8 py-3'>
     
        <div className='col-span-3 lg:col-span-2 w-full h-full shadow-xl shadow-gray-400 rounded-xl p-4'>
          <img
            className='rounded-xl hover:scale-105 ease-in duration-300'
            src={ContactImg}
            alt='Contact'
          />
        </div>

       
        <div className='col-span-2 lg:col-span-3'>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
