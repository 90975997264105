
import React from 'react';
import cryptoImg from '../assets/projects/crypto.jpg';

const Crypto = () => {
  return (
    <div className='w-full'>
      <div className='w-screen h-[50vh] relative'>
        <div className='absolute top-0 left-0 w-full h-[50vh] bg-black/70 z-10' />
        <img
          className='absolute z-1'
          layout='fill'
          objectFit='cover'
          src={cryptoImg}
          alt='/'
        />
        <div className='absolute top-[70%] max-w-[1240px] w-full left-[50%] right-[50%] translate-x-[-50%] translate-y-[-50%] text-white z-10 p-2'>
          <h2 className='py-2'>Crypto App</h2>
          <h3>React JS / Tailwind / Firebase</h3>
        </div>
      </div>
      <div className='max-w-[1240px] mx-auto p-2 grid md:grid-cols-5 gap-8 py-8'>
        <div className='col-span-4'>
          <p>Projecto</p>
          <h2>medecode.net</h2>
<p>
  He desarrollado esta aplicación para demostrar mi conocimiento trabajando
   en React JS y accediendo a puntos finales de APIs. Esta aplicación fue 
   construida utilizando React JS y está alojada en Firebase. 
   Esta aplicación admite la autenticación de usuarios utilizando Firebase.
    Los usuarios pueden crear una nueva cuenta utilizando su dirección 
    de correo electrónico y luego iniciar sesión para guardar monedas en 
    una lista específica del usuario. Esto es posible gracias a la 
    creación de una base de datos de almacenamiento en la nube de Firestore en 
    el momento del registro del usuario. Otra característica de esta 
    aplicación es el enrutamiento dinámico a través del paquete
     &quot;React Router DOM&quot;. Esta aplicación fue construida 
     utilizando la API de Coin Gecko.
</p>
          <a
            href='https://github.com' target='_blank'  rel='noreferrer'
          >
            <button className='px-8 py-2 mt-4 mr-8'>Code</button>
          </a>
          <a
            href='https://cryptobase' target='_blank'  rel='noreferrer'>
            <button className='px-8 py-2 mt-4'>Demo</button>
          </a>
        </div>
        <div className='col-span-4 md:col-span-1 shadow-xl shadow-gray-400 rounded-xl py-4'>
          <div className='p-2'>
            <p className='text-center font-bold pb-2'>Tecnologías</p>
            <div className='grid grid-cols-3 md:grid-cols-1 '>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> React
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Tailwind
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Javascript
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Firebase
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Coin Gecko API
              </p>
              <p className='text-gray-600 py-2 flex items-center'>
                <span className='pr-1' /> Routes
              </p>
            </div>
          </div>
        </div>
        <a href='/#projects'>
          <p className='underline cursor-pointer'>Volver</p>
        </a>
      </div>
    </div>
  );
};
export default Crypto;
