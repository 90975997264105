import React from 'react';
import  C from '../components/Contact';

const Contact = () => {
  return (
    <div className='w-full'>
 <C />

    </div>
  );
};
export default Contact ;
