import React from 'react';
import  Contact from '../components/Contact';
const Mobil = () => {
  return (
    <div className='w-full'>
 <Contact />
    </div>
  );
};
export default Mobil ;
